<template>
	<div>
		<Breadcrumbs :items="crumbs" />
		<div class="dashboard__main">

			<transition name="fadeStop">
	      <Loader v-if="(!userProfile && !customerProfile)" />
	    </transition>

	    <transition name="fadeStop">
	    	<div v-if="customerProfile && customerProfile.access == 'entity'">
	    		<h1 class="ml-3 mt-3">Customer Dashboard</h1>
	    		<hr>
	    		<div class="flex flex-wrap">
            <div class="flex__col1">
            	
            	<transition name="fadeStop">
            		<div>
	            		<div class="list__item">
						        <div class="list__item--body--sm">
						          <div class="inline-block">
						            <h3 class="mb-0">Place a New Order</h3>
						          </div>
						        </div>
						        <div class="list__button">
						          <router-link :to="{name: 'newOrder'}" tag="button" class="btn">
						           <i class="fad fa-arrow-right fa-2x"></i>
						          </router-link>
						        </div>
						      </div>
						      <div class="list__item">
						        <div class="list__item--body--sm">
						          <div class="inline-block">
						            <h3 class="mb-0">Order History</h3>
						          </div>
						        </div>
						        <div class="list__button">
						          <router-link :to="{name: 'custOrders'}" tag="button" class="btn">
						           <i class="fad fa-arrow-right fa-2x"></i>
						          </router-link>
						        </div>
						      </div>
						      <div class="list__item">
						        <div class="list__item--body--sm">
						          <div class="inline-block">
						            <h3 class="mb-0">Account Details</h3>
						          </div>
						        </div>
						        <div class="list__button">
						          <router-link :to="{name: 'account'}" tag="button" class="btn">
						           <i class="fad fa-arrow-right fa-2x"></i>
						          </router-link>
						        </div>
						      </div>
						      <div class="card mt-4">
						      	<button class="btn btn__outlined" @click="logout()">Log Out</button>
						      </div>
					      </div>
            	</transition>
            </div>
            <div class="flex__col2">
            	<div class="card mb-3">
            		<h4>
            			Customer Service Information
            		</h4>
            		<hr>
            		<p><strong>Regular Business Hours:</strong></p>
            		<p>Gina Moretti</p>
            		<p>Customer Service Manager</p>
            		<p>Office Phone: (925) 425-9282</p>
            		<p>Fax: 866-336-89692</p>
            		<p>Email: gina@medeamp.com</p>
            		<br>
            		<p><strong>After Hours:</strong></p>
            		<p>Brandon Laidlaw</p>
            		<p>Chief Executive Officer</p>
            		<p>Cell Phone: (925) 872-1000</p>
            		<p>Office Phone: (925) 425-9282</p>
            		<p>Fax: 866-336-8969</p>
            		<p>Email: brandon@medeamp.com</p>
            	</div>
            	<div class="card mb-3">
            		<h4>
            			Our Vendor Details
            		</h4>
            		<hr>
            		<p>Here is all of the information you might need to add us as a vendor in your system.</p>
            		<br>
            		<p><strong>Medea Inc. dba Medea Medical Products</strong></p>
            		<p>State of California Contract Number: 1-20-65-32S</p>
            		<p>Office Phone: (925) 425-9282</p>
            		<p>Fax: 866-336-8969</p>
            		<br>
            		<address>
            			Medea Medical Products<br>
            			5653 Stoneridge Drive, Suite 119 <br>
            			Pleasanton, CA 94588
            		</address>
            		<br>
            		<p><strong>Contract Manager:</strong></p>
            		<p>Michael Laidlaw</p>
            		<p>Executive V.P. & General Counsel</p>
            		<p>Office Phone: (925) 425-9282</p>
            		<p>Cell Phone: (916) 769-6210</p>
            		<p>Email: michael@medeamp.com</p>
            	</div>
            </div>
          </div>
	    	</div>
	    </transition>

	    <transition name="fadeStop">
	    	<div v-if="userProfile && userProfile.access == 'admin'">
	    		<h1 class="ml-3 mt-3">Admin</h1>
					<hr>
					<div class="flex flex-wrap">
            <div class="flex__col1">
            	<div class="list__item">
				        <div class="list__item--body--sm">
				          <div class="inline-block">
				            <h3 class="mb-0">Manage Products</h3>
				          </div>
				        </div>
				        <div class="list__button">
				          <router-link :to="{name: 'dashProducts'}" tag="button" class="btn">
				           <i class="fad fa-arrow-right fa-2x"></i>
				          </router-link>
				        </div>
				      </div>
				      <div class="list__item">
				        <div class="list__item--body--sm">
				          <div class="inline-block">
				            <h3 class="mb-0">Manage Customers</h3>
				          </div>
				        </div>
				        <div class="list__button">
				          <router-link :to="{name: 'dashCustomers'}" tag="button" class="btn">
				           <i class="fad fa-arrow-right fa-2x"></i>
				          </router-link>
				        </div>
				      </div>
				      <div class="list__item">
				        <div class="list__item--body--sm">
				          <div class="inline-block">
				            <h3 class="mb-0">Manage Orders</h3>
				          </div>
				        </div>
				        <div class="list__button">
				          <router-link :to="{name: 'dashOrders'}" tag="button" class="btn">
				           <i class="fad fa-arrow-right fa-2x"></i>
				          </router-link>
				        </div>
				      </div>
				      <div class="card mt-4">
				      	<button class="btn btn__outlined" @click="logout()">Log Out</button>
				      </div>
            </div>
            <div class="flex__col2">
            </div>
          </div>
	      </div>
	    </transition>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  name: 'dashHome',
  data: () => ({

  }),
  components: {
  	Loader,
  	Breadcrumbs
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'customerProfile']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: false}
      crumbsArray.push(step1)
      return crumbsArray
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  },
  beforeDestroy() {
  }
}
</script>